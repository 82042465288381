<template>
  <div class="mb-3">
    <label
      v-if="showLabel"
      class="form-label"
    >
      {{ label || '' }}
      <i
        v-if="description"
        ref="tooltip-toggler"
        :title="description"
        class="bi bi-info-circle"
        data-bs-toggle="tooltip"
      />
    </label>
    <div class="m-3 mt-0 form-check d-flex flex-column">
      <div
        v-for="(name, index) in options"
        :key="index"
      >
        <input
          :id="`radiobutton-${index}`"
          v-model="value"
          :readonly="readonly"
          :value="name"
          class="form-check-input"
          type="radio"
        />
        <label
          class="form-check-label"
          :for="`radiobutton-${index}`"
        >
          {{ name }}
        </label>
      </div>
    </div>
  </div>
</template>
<script>
import { Tooltip } from 'bootstrap';

export default {
  props: [ 'modelValue', 'label', 'description', 'readonly', 'options' ],
  emits: ['update:modelValue'],
  computed: {
    showLabel() {
      return this.label || this.description;
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    }
  },
  mounted() {
    if (this.description) {
      new Tooltip(this.$refs['tooltip-toggler']);
    }
  }
};
</script>
