<template>
  <div
    class="mb-3"
  >
    <label class="form-label">
      Working Days
      <i
        v-if="description"
        ref="tooltip-toggler"
        :title="description"
        class="bi bi-info-circle"
        data-bs-toggle="tooltip"
      />
    </label>
    <div
      :class="{ 'is-invalid': hasErrors }"
      class="d-flex working-days"
    >
      <div class="d-flex flex-column p-2">
        <input
          id="working-day-sun"
          v-model="value"
          :value="7"
          name="working-day-sun"
          type="checkbox"
          @blur="onChangeHandler"
        />
        <label for="working-day-sun">Sun</label>
      </div>
      <div class="d-flex flex-column p-2">
        <input
          id="working-day-mon"
          v-model="value"
          :value="1"
          name="working-day-mon"
          type="checkbox"
          @blur="onChangeHandler"
        />
        <label for="working-day-mon">Mon</label>
      </div>
      <div class="d-flex flex-column p-2">
        <input
          id="working-day-tues"
          v-model="value"
          :value="2"
          name="working-day-tues"
          type="checkbox"
          @blur="onChangeHandler"
        />
        <label for="working-day-tues">Tue</label>
      </div>
      <div class="d-flex flex-column p-2">
        <input
          id="working-day-wed"
          v-model="value"
          :value="3"
          name="working-day-wed"
          type="checkbox"
          @blur="onChangeHandler"
        />
        <label for="working-day-wed">Wed</label>
      </div>
      <div class="d-flex flex-column p-2">
        <input
          id="working-day-thurs"
          v-model="value"
          :value="4"
          name="working-day-thurs"
          type="checkbox"
          @blur="onChangeHandler"
        />
        <label for="working-day-thurs">Thu</label>
      </div>
      <div class="d-flex flex-column p-2">
        <input
          id="working-day-fri"
          v-model="value"
          :value="5"
          name="working-day-fri"
          type="checkbox"
          @change="onChangeHandler"
        />
        <label for="working-day-fri">Fri</label>
      </div>
      <div class="d-flex flex-column p-2">
        <input
          id="working-day-sat"
          v-model="value"
          :value="6"
          name="working-day-sat"
          type="checkbox"
          @change="onChangeHandler"
        />
        <label for="working-day-sat">Sat</label>
      </div>
    </div>
    <div class="invalid-feedback">
      <p
        v-for="error in errors"
        :key="error.$uid"
      >
        {{ error.$message }}
      </p>
    </div>
  </div>
</template>
<script>
import { Tooltip } from 'bootstrap';

export default {
  props: [ 'modelValue', 'errors', 'description' ],
  emits: [ 'update:modelValue', 'change' ],
  computed: {
    hasErrors() {
      return this.errors?.length > 0;
    },
    value: {
      get() {
        if (!this.modelValue) {
          return [];
        }
        return [...this.modelValue];
      },
      set(val) {
        val.sort();
        this.$emit('update:modelValue', val);
      }
    }
  },
  mounted() {
    if (this.description) {
      new Tooltip(this.$refs['tooltip-toggler']);
    }
  },
  methods: {
    onChangeHandler() {
      this.$emit('change', this.value);
    }
  }
};
</script>
<style scoped>
.working-days {
}

.working-days.is-invalid {
  border: 1px solid #dc3545;
  border-radius: 0.25rem;
}
</style>
