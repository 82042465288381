<template>
  <div class="mb-3">
    <label
      v-if="showLabel"
      class="form-label"
    >
      {{ label || '' }}
      <i
        v-if="description"
        ref="tooltip-toggler"
        :title="description"
        class="bi bi-info-circle"
        data-bs-toggle="tooltip"
      />
    </label>
    <div
      :class="{ 'is-invalid': hasErrors }"
      class="d-flex time-input"
    >
      <div class="input-group m-1">
        <div class="input-group-prepend">
          <span
            id="inputGroupPrepend"
            class="input-group-text"
          >hh:</span>
        </div>
        <select
          :readonly="readonly"
          :value="value.hh"
          class="form-select w-25"
          required
          @change="onChangeHandler"
          @input="e => updateModelValue(e, 'hh')"
        >
          <option
            v-for="num in hourOptions"
            :key="num"
            :value="num"
          >
            {{ num }}
          </option>
        </select>
      </div>
      <div class="input-group m-1">
        <div class="input-group-prepend">
          <span
            id="inputGroupPrepend"
            class="input-group-text"
          >mm:</span>
        </div>
        <select
          :readonly="readonly"
          :value="value.mm"
          class="form-select w-25"
          required
          @change="onChangeHandler"
          @input="e => updateModelValue(e, 'mm')"
        >
          <option
            v-for="num in timeOptions"
            :key="num"
            :value="num"
          >
            {{ num }}
          </option>
        </select>
      </div>
      <div class="input-group m-1">
        <div class="input-group-prepend">
          <span
            id="inputGroupPrepend"
            class="input-group-text"
          >ss:</span>
        </div>
        <select
          :readonly="readonly"
          :value="value.ss"
          class="form-select w-25"
          required
          @change="onChangeHandler"
          @input="e => updateModelValue(e, 'ss')"
        >
          <option
            v-for="num in timeOptions"
            :key="num"
            :value="num"
          >
            {{ num }}
          </option>
        </select>
      </div>
    </div>
    <div class="invalid-feedback">
      <p
        v-for="error in errors"
        :key="error.$uid"
      >
        {{ error.$message }}
      </p>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import { Tooltip } from 'bootstrap';

const defaultTimeValue = { hh: '--', mm: '--', ss: '--' };
export default {
  props: [ 'modelValue', 'label', 'description', 'readonly', 'errors' ],
  emits: [ 'update:modelValue', 'change' ],
  computed: {
    hasErrors() {
      return this.errors?.length > 0;
    },
    showLabel() {
      return this.label || this.description;
    },
    value: {
      get() {
        if (!this.modelValue) {
          return defaultTimeValue;
        }
        const pieces = this.modelValue.split(':');
        if (pieces.length !== 3) {
          return defaultTimeValue;
        }
        return { hh: pieces[0], mm: pieces[1], ss: pieces[2] };
      },
      set(val) {
        if (!val) {
          this.$emit('update:modelValue', null);
          return;
        }
        this.$emit('update:modelValue', `${val.hh}:${val.mm}:${val.ss}`);
      }
    },
    hourOptions() {
      const numbers = _.range(0, 24);
      return [ '--', ..._.map(numbers, this.formatTimeIndication) ];
    },
    timeOptions() {
      const numbers = _.range(0, 60);
      return [ '--', ..._.map(numbers, this.formatTimeIndication) ];
    }
  },
  mounted() {
    if (this.description) {
      new Tooltip(this.$refs['tooltip-toggler']);
    }
  },
  methods: {
    formatTimeIndication(t) {
      return t < 10 ? `0${t}` : `${t}`;
    },
    updateModelValue(e, entryType) {
      // we need set null value for the time,
      // so we set null when any of selectors values
      // equals to "--"
      if (e.target.value === '--') {
        this.value = null;
        return;
      }
      const update = { [`${entryType}`]: e.target.value };
      this.value = { ...this.value, ...update };
    },
    onChangeHandler() {
      this.$emit('change', this.value);
    }
  }
};
</script>
<style scoped>
.time-input {
}

.time-input.is-invalid {
  border: 1px solid #dc3545;
  border-radius: 0.25rem;
}
</style>
