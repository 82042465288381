<template>
  <div
    id="sla-edit-modal"
    ref="sla-edit-modal"
    aria-hidden="true"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ modalTitle }}
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            type="button"
          />
        </div>
        <div class="modal-body">
          <form v-if="initialized">
            <ul
              id="myTab"
              class="nav nav-tabs"
              role="tablist"
            >
              <li
                class="nav-item"
                role="presentation"
              >
                <button
                  id="general-tab"
                  aria-controls="general"
                  aria-selected="true"
                  class="nav-link active"
                  data-bs-target="#general"
                  data-bs-toggle="tab"
                  role="tab"
                  type="button"
                >
                  General
                </button>
              </li>
              <li
                class="nav-item"
                role="presentation"
              >
                <button
                  id="profile-tab"
                  aria-controls="fulfilment"
                  aria-selected="false"
                  class="nav-link"
                  data-bs-target="#fulfilment"
                  data-bs-toggle="tab"
                  role="tab"
                  type="button"
                >
                  Fulfilment
                </button>
              </li>
              <li
                class="nav-item"
                role="presentation"
              >
                <button
                  id="receiving-tab"
                  aria-controls="receiving"
                  aria-selected="false"
                  class="nav-link"
                  data-bs-target="#receiving"
                  data-bs-toggle="tab"
                  role="tab"
                  type="button"
                >
                  Receiving
                </button>
              </li>
              <li
                class="nav-item"
                role="presentation"
              >
                <button
                  id="fulfilment-wh-tab"
                  aria-controls="fulfilment-wh"
                  aria-selected="false"
                  class="nav-link"
                  data-bs-target="#fulfilment-wh"
                  data-bs-toggle="tab"
                  role="tab"
                  type="button"
                >
                  Fulfilment Wholesale
                </button>
              </li>
              <li
                class="nav-item"
                role="presentation"
              >
                <button
                  id="last-touch-manufacturing-tab"
                  aria-controls="last-touch-manufacturing"
                  aria-selected="false"
                  class="nav-link"
                  data-bs-target="#last-touch-manufacturing"
                  data-bs-toggle="tab"
                  role="tab"
                  type="button"
                >
                  Last Touch Manufacturing
                </button>
              </li>
              <li
                class="nav-item"
                role="presentation"
              >
                <button
                  id="peak-seasons-tab"
                  aria-controls="peak-seasons"
                  aria-selected="false"
                  class="nav-link"
                  data-bs-target="#peak-seasons"
                  data-bs-toggle="tab"
                  role="tab"
                  type="button"
                  @click="loadBrandPeakSeasons"
                >
                  Peak Seasons
                </button>
              </li>
            </ul>
            <div
              id="tabContent"
              class="tab-content"
            >
              <div
                id="general"
                aria-labelledby="home-tab"
                class="tab-pane fade show active p-3"
                role="tabpanel"
              >
                <SlaConfigurationTextInput
                  v-model="item.brand"
                  :errors="v$.item.brand.$errors"
                  description="Brand code. Usually two letters in upper case"
                  label="Brand"
                  :readonly="item.id"
                  @blur="v$.item.brand.$touch"
                />
                <SlaConfigurationTextInput
                  v-model="holidayProcessingMaxDays"
                  :errors="v$.holidayProcessingMaxDays.$errors"
                  description="Number of additional days, if received order during holidays or peak seasons, like Nov 20 - Dec 20 period"
                  label="Peak Season and Holidays Processing Max Days"
                  @blur="v$.holidayProcessingMaxDays.$touch"
                />
                <CheckboxInput
                  v-model="item.holidayExceptionEnabled"
                  description="Annual holiday exceptions"
                  label="Holiday Exception"
                />
                <CheckboxInput
                  v-model="item.holidayShiftExceptionEnabled"
                  description="Holiday on Sa / Su then the following Monday will also be holiday"
                  label="Holiday Shift Exception"
                />
                <CheckboxInput
                  v-model="item.peakSeasonProcessorEnabled"
                  description="Indicator to process or not peak seasons like Nov 20 - Dec 20 period"
                  label="Consider Peak Seasons"
                />
                <RadioButton
                  v-model="peakSeasonCalculationDays"
                  description="Which days will be used in the SLA calculations"
                  label="Peak Season Calculation Days:"
                  :options="peakCalculationDaysOptions"
                />
                <SlaConfigurationWorkingDays
                  v-model="item.workingDays"
                  :errors="v$.item.workingDays.$errors"
                  description="Working days"
                  @change="v$.item.workingDays.$touch"
                />
                <SlaConfigurationTimeInput
                  v-model="item.postHoldTime"
                  :errors="v$.item.postHoldTime.$errors"
                  description="Post Hold Time"
                  label="Post Hold Time"
                  @change="v$.item.postHoldTime.$touch"
                />
                <hr />
              </div>
              <div
                id="fulfilment"
                aria-labelledby="fulfilment-tab"
                class="tab-pane fade p-3"
                role="tabpanel"
              >
                <SlaConfigurationTextInput
                  v-model="fulfilmentProcessingDays"
                  :errors="v$.fulfilmentProcessingDays.$errors"
                  description="Number of days to process the order"
                  label="Fulfilment Processing Days"
                  @blur="v$.fulfilmentProcessingDays.$touch"
                />
                <SlaConfigurationTimeInput
                  v-model="item.ffReceivedMaxTime"
                  :errors="v$.item.ffReceivedMaxTime.$errors"
                  description="Weekday cut-off time for orders to be considered received the same day"
                  label="Ff Received Max Time"
                  @change="v$.item.ffReceivedMaxTime.$touch"
                />
                <SlaConfigurationTextInput
                  v-model="item.ffThreshold"
                  :errors="v$.item.ffThreshold.$errors"
                  description="SLA Fulfilment Order completion criteria (%)"
                  label="Ff Threshold"
                  @blur="v$.item.ffThreshold.$touch"
                />
                <SlaConfigurationTimeInput
                  v-model="item.ffSentMaxTime"
                  :errors="v$.item.ffSentMaxTime.$errors"
                  description="Cut-off time for orders to be sent on the next weekday from COR date"
                  label="Ff Sent Max Time"
                  @change="v$.item.ffSentMaxTime.$touch"
                />
                <hr />
              </div>
              <div
                id="receiving"
                aria-labelledby="receiving-tab"
                class="tab-pane fade p-3"
                role="tabpanel"
              >
                <SlaConfigurationTimeInput
                  v-model="item.osrrReceivedMaxTime"
                  :errors="v$.item.osrrReceivedMaxTime.$errors"
                  description="Weekday OSRR cut-off time for Receiving to be considered same day"
                  label="Osrr Received Max Time"
                  @change="v$.item.osrrReceivedMaxTime.$touch"
                />
                <hr />
                <h5 class="mt-4">
                  Receiving:
                </h5>
                <SlaConfigurationTextInput
                  v-model="receivingFulfilmentProcessingDays"
                  :errors="v$.receivingFulfilmentProcessingDays.$errors"
                  description="Number of days to process the order"
                  label="Receiving Fulfilment Processing days"
                  @blur="v$.receivingFulfilmentProcessingDays.$touch"
                />
                <SlaConfigurationTextInput
                  v-model="item.nmsrThreshold"
                  :errors="v$.item.nmsrThreshold.$errors"
                  description="SLA Receiving NMSR completion criteria (%). Usual value is 99."
                  label="Nmsr Threshold"
                  @blur="v$.item.nmsrThreshold.$touch"
                />
                <SlaConfigurationTimeInput
                  v-model="item.nmsrSentMaxTime"
                  :errors="v$.item.nmsrSentMaxTime.$errors"
                  description="Non-mixed SKU receiving by the following time"
                  label="Nmsr Sent Max Time"
                  @change="v$.item.nmsrSentMaxTime.$touch"
                />
                <hr />
                <h5 class="mt-4">
                  Unit Receiving:
                </h5>
                <SlaConfigurationTextInput
                  v-model="unitReceivingProcessingDays"
                  :errors="v$.unitReceivingProcessingDays.$errors"
                  description="Number of days to process the order"
                  label="Unit Receiving Processing days"
                  @blur="v$.unitReceivingProcessingDays.$touch"
                />
                <SlaConfigurationTextInput
                  v-model="item.urThreshold"
                  :errors="v$.item.urThreshold.$errors"
                  description="SLA Receiving UR completion criteria (%). Usual value is 99."
                  label="Ur Threshold"
                  @blur="v$.item.urThreshold.$touch"
                />
                <SlaConfigurationTimeInput
                  v-model="item.urSentMaxTime"
                  :errors="v$.item.urSentMaxTime.$errors"
                  description="Unit receiving by the following time"
                  label="Ur Sent Max Time"
                  @change="v$.item.urSentMaxTime.$touch"
                />
              </div>
              <div
                id="fulfilment-wh"
                aria-labelledby="fulfilment-wh-tab"
                class="tab-pane fade p-3"
                role="tabpanel"
              >
                <div class="form-check form-switch pb-1">
                  <input
                    v-model="whRuleEnable"
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    @change="changeWhRuleEnable"
                  />
                  <label
                    class="form-check-label"
                  >
                    Enable/disable WH rule
                  </label>
                </div>
                <SlaConfigurationTextInput
                  v-if="whRuleEnable"
                  v-model="fulfilmentWholesaleProcessingDays"
                  :errors="v$.fulfilmentWholesaleProcessingDays.$errors"
                  description="Number of days to process the order"
                  label="Fulfilment Wholesale Processing Days"
                  @blur="v$.fulfilmentWholesaleProcessingDays.$touch"
                />
                <SlaConfigurationTimeInput
                  v-if="whRuleEnable"
                  v-model="item.ffWholesaleReceivedMaxTime"
                  :errors="v$.item.ffWholesaleReceivedMaxTime.$errors"
                  description="Weekday cut-off time for orders to be considered received the same day"
                  label="Ff Wholesale Received Max Time"
                  @change="v$.item.ffWholesaleReceivedMaxTime.$touch"
                />
                <hr />
              </div>
              <div
                id="last-touch-manufacturing"
                aria-labelledby="last-touch-manufacturing-tab"
                class="tab-pane fade p-3"
                role="tabpanel"
              >
                <div class="form-check form-switch pb-1">
                  <input
                    v-model="ltmRuleEnable"
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    @change="changeLtmRuleEnable"
                  />
                  <label
                    class="form-check-label"
                  >
                    Enable/disable LTM rule
                  </label>
                </div>
                <SlaConfigurationTimeInput
                  v-if="ltmRuleEnable"
                  v-model="item.ltmSentMaxTime"
                  :errors="v$.item.ltmSentMaxTime.$errors"
                  description="Cut-off time for orders to be sent on the next weekday from COR date"
                  label="LTM Sent Max Time"
                  @change="v$.item.ltmSentMaxTime.$touch"
                />
                <SlaConfigurationTextInput
                  v-if="ltmRuleEnable"
                  v-model="ltmProcessingDays"
                  :errors="v$.ltmProcessingDays.$errors"
                  description="Number of days to process the order"
                  label="LTM Processing Days"
                  @blur="v$.ltmProcessingDays.$touch"
                />
                <SlaConfigurationTimeInput
                  v-if="ltmRuleEnable"
                  v-model="item.ltmReceivedMaxTime"
                  :errors="v$.item.ltmReceivedMaxTime.$errors"
                  description="Weekday cut-off time for orders to be considered received the same day"
                  label="LTM Received Max Time"
                  @change="v$.item.ltmReceivedMaxTime.$touch"
                />
                <hr />
              </div>
              <div
                id="peak-seasons"
                aria-labelledby="peak-seasons-tab"
                class="tab-pane fade p-3"
                role="tabpanel"
              >
                <div
                  v-for="(season) in item.seasons"
                  :key="'season' + season.id"
                  class="peak-seasons-list-item my-1"
                >
                  <span class="mr-2">From: {{ season.startDate }}</span>
                  <span class="mx-2">To: {{ season.endDate }}</span>
                  <span class="mx-2">{{ season.recurrent ? 'Recurrent' : 'Non-recurrent' }}</span>
                  <button
                    class="btn btn-danger ml-2"
                    :data-season-id="season.id"
                    @click="deletePeakSeason"
                  >
                    X
                  </button>
                </div>
                <hr />
                <div
                  class="add-peak-season"
                >
                  <div class="d-flex">
                    <DatePicker
                      v-model="seasonItem.startDate"
                      :formatter="dateFormatter"
                      description="Date from which season starts"
                      label="Start date"
                    />
                    <DatePicker
                      v-model="seasonItem.endDate"
                      :formatter="dateFormatter"
                      description="Date when season ends"
                      label="End date"
                    />
                    <div class="form-check form-switch pb-1">
                      <input
                        v-model="seasonItem.recurrent"
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                      />
                      <label
                        class="form-check-label"
                      >
                        Recurrent
                      </label>
                    </div>
                  </div>
                  <button
                    class="btn btn-sm btn-primary"
                    @click="addNewPeakSeason"
                  >
                    + Add new season
                  </button>
                </div>
              </div>
            </div>
          </form>
          <Spinner v-else />
          <div class="modal-footer">
            <button
              aria-label="Close"
              class="btn btn-success"
              type="submit"
              @click="showConfirmationModalHandler"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ConfirmationModal
    ref="sla-edit-confirmation-modal"
    :onCancelHandler="cancelEditHandler"
    :onConfirmHandler="saveChangesHandler"
  >
    <template #content>
      <div
        class="alert alert-warning"
        role="alert"
      >
        <h6>
          <i class="bi bi-exclamation-triangle" />
          The changes you have just made will only
          apply to all new data coming from DEPOSCO.
          Not historical data.
        </h6>
      </div>
    </template>
  </ConfirmationModal>
</template>
<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { helpers, integer, minLength, minValue, required, requiredIf } from '@vuelidate/validators';
import { Modal } from 'bootstrap';
import ConfirmationModal from '@/components/common/ConfirmationModal';
import SlaConfigurationWorkingDays from '@/components/SlaConfiguration/SlaConfigurationWorkingDays';
import SlaConfigurationTextInput from '@/components/common/TextInput';
import CheckboxInput from '@/components/common/CheckboxInput';
import SlaConfigurationTimeInput from '@/components/SlaConfiguration/SlaConfigurationTimeInput';
import Spinner from '@/components/common/Spinner';
import { defaultValues, secsInDay, timeDiff, validateTimeEntry } from './constants';
import { modalViewTypes } from '@/components/constants';
import RadioButton from '@/components/common/RadioButton';
import DatePicker from '@/components/common/DatePicker.vue';

export default {
  components: {
    DatePicker,
    RadioButton,
    ConfirmationModal,
    Spinner,
    SlaConfigurationWorkingDays,
    SlaConfigurationTextInput,
    CheckboxInput,
    SlaConfigurationTimeInput
  },
  props: [ 'viewType', 'brand', 'brandId' ],
  emits: ['successSave'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      editModal: null,
      confirmationModal: null,
      initialized: false,
      fulfilmentProcessingDays: 1,
      fulfilmentWholesaleProcessingDays: 2,
      ltmProcessingDays: 4,
      receivingFulfilmentProcessingDays: 1,
      unitReceivingProcessingDays: 1,
      holidayProcessingMaxDays: null,
      peakSeasonCalculationDays: null,
      item: {
        id: null,
        brand: null,
        brandId: null,
        holidayExceptionEnabled: null,
        holidayProcessingMaxSeconds: null,
        holidayShiftExceptionEnabled: null,
        peakSeasonProcessorEnabled: null,
        peakSeasonUseCalendarDays: null,
        ffProcessingMaxSeconds: null,
        ffReceivedMaxTime: null,
        workingDays: null,
        ffThreshold: null,
        ffSentMaxTime: null,
        osrrReceivedMaxTime: null,
        nmsrProcessingMaxSeconds: null,
        nmsrThreshold: null,
        nmsrSentMaxTime: null,
        urProcessingMaxSeconds: null,
        urThreshold: null,
        urSentMaxTime: null,
        ffWholesaleProcessingMaxSeconds: null,
        ffWholesaleReceivedMaxTime: null,
        ltmProcessingMaxSeconds: null,
        ltmReceivedMaxTime: null,
        ltmSentMaxTime: null,
        postHoldTime: null,
        seasons: []
      },
      seasonItem: {
        startDate: null,
        endDate: null,
        recurrent: false
      },
      whRuleEnable: false,
      ltmRuleEnable: false,
      allBrandSeasons: {}
    };
  },
  validations() {
    return {
      fulfilmentProcessingDays: { required, minValue: minValue(0), integer },
      fulfilmentWholesaleProcessingDays: {
        required: requiredIf(() => this.isFulfilmentWholesaleSectionEnabled && this.whRuleEnable),
        minValue: minValue(0),
      },
      ltmProcessingDays: {
        required: requiredIf(() => this.isLTMSectionEnabled && this.ltmRuleEnable),
        minValue: minValue(0),
      },
      receivingFulfilmentProcessingDays: {
        required: requiredIf(() => this.isReceivingSectionEnabled),
        minValue: minValue(0), integer
      },
      unitReceivingProcessingDays: {
        required: requiredIf(() => this.isReceivingSectionEnabled),
        minValue: minValue(0), integer
      },
      holidayProcessingMaxDays: { required, minValue: minValue(0), integer },
      item: {
        brand: { required },
        holidayProcessingMaxSeconds: { required, minValue: minValue(0), integer },
        osrrReceivedMaxTime: {
          required: requiredIf(() => this.isReceivingSectionEnabled),
          validateTimeEntry: helpers.withMessage('Value should be a valid time entry', validateTimeEntry)
        },
        ffReceivedMaxTime: {
          required,
          validateTimeEntry: helpers.withMessage('Value should be a valid time entry', validateTimeEntry)
        },
        workingDays: { required, minLength: minLength(1) },
        ffThreshold: { required, minValue: minValue(0) },
        ffSentMaxTime: {
          required,
          validateTimeEntry: helpers.withMessage('Value should be a valid time entry', validateTimeEntry)
        },
        nmsrThreshold: {
          required: requiredIf(() => this.isReceivingSectionEnabled),
          minValue: minValue(0)
        },
        nmsrSentMaxTime: {
          required: requiredIf(() => this.isReceivingSectionEnabled),
          validateTimeEntry: helpers.withMessage('Value should be a valid time entry', validateTimeEntry)
        },
        urThreshold: {
          required: requiredIf(() => this.isReceivingSectionEnabled),
          minValue: minValue(0)
        },
        urSentMaxTime: {
          required: requiredIf(() => this.isReceivingSectionEnabled),
          validateTimeEntry: helpers.withMessage('Value should be a valid time entry', validateTimeEntry)
        },
        ffWholesaleReceivedMaxTime: {
          required: requiredIf(() => this.isFulfilmentWholesaleSectionEnabled && this.whRuleEnable),
          validateTimeEntry: helpers.withMessage('Value should be a valid time entry', validateTimeEntry)
        },
        ltmReceivedMaxTime: {
          required: requiredIf(() => this.isLTMSectionEnabled && this.ltmRuleEnable),
          validateTimeEntry: helpers.withMessage('Value should be a valid time entry', validateTimeEntry)
        },
        ltmSentMaxTime: {
          required: requiredIf(() => this.isLTMSectionEnabled && this.ltmRuleEnable),
          validateTimeEntry: helpers.withMessage('Value should be a valid time entry', validateTimeEntry)
        },
        postHoldTime: {
          required,
          validateTimeEntry: helpers.withMessage('Value should be a valid time entry', validateTimeEntry)
        }
      }
    };
  },
  computed: {
    modalTitle() {
      return this.viewType === modalViewTypes.edit ? 'Edit row' : 'Add New';
    },
    isReceivingSectionEnabled() {
      return this.item.osrrReceivedMaxTime ||
        this.receivingFulfilmentProcessingDays ||
        this.item.nmsrThreshold ||
        this.item.nmsrSentMaxTime ||
        this.unitReceivingProcessingDays ||
        this.item.urThreshold ||
        this.item.urSentMaxTime;
    },
    isFulfilmentWholesaleSectionEnabled() {
      return this.fulfilmentWholesaleProcessingDays || this.item.ffWholesaleReceivedMaxTime;
    },
    isLTMSectionEnabled() {
      return this.ltmProcessingDays || this.item.ltmReceivedMaxTime || this.item.ltmSentMaxTime;
    },
    ffProcMaxSeconds() {
      return this.calculateMaxProcessingTime(this.item.ffSentMaxTime, this.item.ffReceivedMaxTime, this.fulfilmentProcessingDays);
    },
    nmsrProcessingMaxSeconds() {
      return this.calculateMaxProcessingTime(this.item.nmsrSentMaxTime, this.item.osrrReceivedMaxTime, this.receivingFulfilmentProcessingDays);
    },
    urProcessingMaxSeconds() {
      return this.calculateMaxProcessingTime(this.item.urSentMaxTime, this.item.osrrReceivedMaxTime, this.unitReceivingProcessingDays);
    },
    ffWholesaleProcessingMaxSeconds() {
      return this.calculateMaxProcessingTime(this.item.ffSentMaxTime, this.item.ffWholesaleReceivedMaxTime, this.fulfilmentWholesaleProcessingDays);
    },
    ltmProcessingMaxSeconds() {
      return this.calculateMaxProcessingTime(this.item.ltmSentMaxTime, this.item.ltmReceivedMaxTime, this.ltmProcessingDays);
    },
    peakCalculationDaysTypes() {
      return { businessDays: 'Business Days', calendarDays: 'Calendar Days' };
    },
    peakCalculationDaysOptions() {
      return _.values(this.peakCalculationDaysTypes);
    }
  },
  mounted() {
    this.editModal = new Modal(this.$refs['sla-edit-modal']);
    this.confirmationModal = this.$refs['sla-edit-confirmation-modal'].confirmationModal;
  },
  methods: {
    ...mapActions({
      createItem: 'sla/createItem',
      updateItem: 'sla/updateItem',
      fetchSlaConfigItemById: 'sla/fetchSlaConfigItemById',
      fetchSlaConfigItems: 'sla/fetchSlaConfigItems',
      createSlaPeakSeasonForBrand: 'sla/createSlaPeakSeasonForBrand',
      deleteSlaPeakSeasonById: 'sla/deleteSlaPeakSeasonById',
      fetchSlaPeakSeasons: 'sla/fetchSlaPeakSeasonsByBrandId'
    }),
    changeLtmRuleEnable() {
      if (this.ltmRuleEnable === false) {
        this.ltmProcessingDays = null;
        this.item.ltmReceivedMaxTime = null;
        this.item.ltmSentMaxTime = null;
      }
      else {
        this.ltmProcessingDays = defaultValues.ltmProcessingDays;
        this.item.ltmReceivedMaxTime = defaultValues.ltmReceivedMaxTime;
        this.item.ltmSentMaxTime = defaultValues.ltmSentMaxTime;
      }
    },
    changeWhRuleEnable() {
      if (this.whRuleEnable === false) {
        this.fulfilmentWholesaleProcessingDays = null;
        this.item.ffWholesaleReceivedMaxTime = null;
      }
      else {
        this.fulfilmentWholesaleProcessingDays = defaultValues.fulfilmentWholesaleProcessingDays;
        this.item.ffWholesaleReceivedMaxTime = defaultValues.ffWholesaleReceivedMaxTime;
      }
    },
    async initModal(itemId) {
      this.editModal.show();
      this.initialized = false;
      const itemToEdit = itemId ? await this.fetchSlaConfigItemById(itemId) : { ...defaultValues };
      if (!itemId && this.brand && this.brandId) {
        itemToEdit.brand = this.brand;
        itemToEdit.brandId = this.brandId;
        itemToEdit.ffWholesaleReceivedMaxTime = null;
        itemToEdit.ltmReceivedMaxTime = null;
      }
      this.updateItemData(itemToEdit);
      this.initialized = true;
    },
    updateItemData(newValue) {
      this.item.id = newValue.id;
      this.item.brand = newValue.brand;
      this.item.brandId = newValue.brandId;
      this.item.holidayExceptionEnabled = newValue.holidayExceptionEnabled;
      this.item.holidayProcessingMaxSeconds = newValue.holidayProcessingMaxSeconds;
      this.item.holidayShiftExceptionEnabled = newValue.holidayShiftExceptionEnabled;
      this.item.peakSeasonProcessorEnabled = newValue.peakSeasonProcessorEnabled;
      this.item.peakSeasonUseCalendarDays = newValue.peakSeasonUseCalendarDays;
      this.item.ffProcessingMaxSeconds = newValue.ffProcessingMaxSeconds;
      this.item.ffReceivedMaxTime = newValue.ffReceivedMaxTime;
      this.item.workingDays = newValue.workingDays;
      this.item.ffThreshold = newValue.ffThreshold;
      this.item.ffSentMaxTime = newValue.ffSentMaxTime;
      this.item.osrrReceivedMaxTime = newValue.osrrReceivedMaxTime;
      this.item.nmsrProcessingMaxSeconds = newValue.nmsrProcessingMaxSeconds;
      this.item.nmsrThreshold = newValue.nmsrThreshold;
      this.item.nmsrSentMaxTime = newValue.nmsrSentMaxTime;
      this.item.urProcessingMaxSeconds = newValue.urProcessingMaxSeconds;
      this.item.urThreshold = newValue.urThreshold;
      this.item.urSentMaxTime = newValue.urSentMaxTime;
      this.item.ffWholesaleReceivedMaxTime = newValue.ffWholesaleReceivedMaxTime;
      this.item.ffWholesaleProcessingMaxSeconds = newValue.ffWholesaleProcessingMaxSeconds;
      this.item.ltmProcessingMaxSeconds = newValue.ltmProcessingMaxSeconds;
      this.item.ltmReceivedMaxTime = newValue.ltmReceivedMaxTime;
      this.item.ltmSentMaxTime = newValue.ltmSentMaxTime;
      this.item.postHoldTime = newValue.postHoldTime;

      this.holidayProcessingMaxDays = this.item.holidayProcessingMaxSeconds / secsInDay;
      this.fulfilmentProcessingDays = this.calculateNumProcessingDays(this.item.ffSentMaxTime, this.item.ffReceivedMaxTime, this.item.ffProcessingMaxSeconds);
      this.receivingFulfilmentProcessingDays = this.calculateNumProcessingDays(this.item.nmsrSentMaxTime, this.item.osrrReceivedMaxTime, this.item.nmsrProcessingMaxSeconds);
      this.unitReceivingProcessingDays = this.calculateNumProcessingDays(this.item.urSentMaxTime, this.item.osrrReceivedMaxTime, this.item.urProcessingMaxSeconds);
      this.peakSeasonCalculationDays = this.item.peakSeasonUseCalendarDays ?
        this.peakCalculationDaysTypes.calendarDays : this.peakCalculationDaysTypes.businessDays;

      if (this.item.id) {
        this.fulfilmentWholesaleProcessingDays = this.calculateNumProcessingDays(this.item.ffSentMaxTime, this.item.ffWholesaleReceivedMaxTime, this.item.ffWholesaleProcessingMaxSeconds);
        this.ltmProcessingDays = this.calculateNumProcessingDays(this.item.ltmSentMaxTime, this.item.ltmReceivedMaxTime, this.item.ltmProcessingMaxSeconds);
      } else {
        this.fulfilmentWholesaleProcessingDays = undefined;
        this.ltmProcessingDays = undefined;
      }

      this.whRuleEnable = this.fulfilmentWholesaleProcessingDays !== undefined;
      this.ltmRuleEnable = this.ltmProcessingDays !== undefined;

      this.seasons = newValue.seasons || [];
    },
    calculateMaxProcessingTime(sentCutoffTime, corrCutoffTime, numDays) {
      if (!sentCutoffTime || !corrCutoffTime || !numDays) {
        return undefined;
      }
      return timeDiff(corrCutoffTime, sentCutoffTime) + numDays * secsInDay;
    },
    calculateNumProcessingDays(sentCutoffTime, corrCutoffTime, maxProcessingTimeSeconds) {
      if (!sentCutoffTime || !corrCutoffTime || !maxProcessingTimeSeconds) {
        return undefined;
      }
      return (maxProcessingTimeSeconds - timeDiff(corrCutoffTime, sentCutoffTime)) / secsInDay;
    },
    async showConfirmationModalHandler() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$toast.error('Form should be valid.');
        return false;
      }
      this.editModal.hide();
      this.confirmationModal.show();
    },
    cancelEditHandler() {
      this.confirmationModal.hide();
    },
    async saveChangesHandler() {
      const data = {
        ...this.item,
        ffProcessingMaxSeconds: this.ffProcMaxSeconds,
        nmsrProcessingMaxSeconds: this.nmsrProcessingMaxSeconds,
        urProcessingMaxSeconds: this.urProcessingMaxSeconds,
        holidayProcessingMaxSeconds: this.holidayProcessingMaxDays * secsInDay,
        ffWholesaleProcessingMaxSeconds: this.ffWholesaleProcessingMaxSeconds,
        ltmProcessingMaxSeconds: this.ltmProcessingMaxSeconds,
        peakSeasonUseCalendarDays: this.peakSeasonCalculationDays === this.peakCalculationDaysTypes.calendarDays
      };
      const dataToSave = _.omit(data, 'seasons');
      try {
        if (dataToSave.id) {
          await this.updateItem(dataToSave);
          this.$toast.success('Item successfully updated');
        } else {
          await this.createItem(dataToSave);
          this.$toast.success('Item successfully created');
        }
        await this.fetchSlaConfigItems();
        this.$emit('successSave');
      } catch (e) {
        this.$toast.error('Failed to save the data.');
      }
    },
    dateFormatter(date, removeYear) {
      let dateToFormat = typeof date === 'string' ? Date.parse(date) : date;

      const options = { day: '2-digit', month: 'short' };
      if (!removeYear) {
        options.year = 'numeric';
      }

      const formatter = new Intl.DateTimeFormat('en-us', options);

      return formatter.format(dateToFormat).replace(',', '');
    },
    async addNewPeakSeason(e) {
      e.preventDefault();

      const actualBrandSeasonsList = await this.createSlaPeakSeasonForBrand({
        startDate: this.dateFormatter(this.seasonItem.startDate, this.seasonItem.recurrent),
        endDate: this.dateFormatter(this.seasonItem.endDate, this.seasonItem.recurrent),
        brandId: this.item.brandId,
        recurrent: this.seasonItem.recurrent
      });
      this.item.seasons = actualBrandSeasonsList;
      this.allBrandSeasons[this.item.brandId] = actualBrandSeasonsList;

      this.seasonItem = {
        startDate: null,
        endDate: null,
        recurrent: false
      };

      return false;
    },
    async deletePeakSeason(e) {
      e.preventDefault();

      const seasonId = e.target.dataset.seasonId;
      this.allBrandSeasons[this.item.brandId] = await this.deleteSlaPeakSeasonById({
        seasonId,
        brandId: this.item.brandId
      });
      this.item.seasons = [...this.allBrandSeasons[this.item.brandId]];

      return false;
    },
    async loadBrandPeakSeasons() {
      if (!this.item.brandId) {
        this.item.seasons = [];
        return;
      }

      if (!this.allBrandSeasons[this.item.brandId]) {
        this.allBrandSeasons[this.item.brandId] = await this.fetchSlaPeakSeasons(this.item.brandId);
      }

      this.item.seasons = [...this.allBrandSeasons[this.item.brandId]];
    }
  }
};
</script>
